// Import necessary modules and components from React and Bootstrap
import React, { useState, useEffect, useContext } from 'react';
import '../../css/RealEstate.css'; // Import custom CSS styles
import { Modal, Button, Form} from 'react-bootstrap'; // Import Modal, Button, Form, Tabs, and Tab components from React Bootstrap
import Select from 'react-select'; // Import react-select for searchable dropdown
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { formatRupees, fetchData, formatDateToInputValue, ConvertDateToMilliseconds } from '../../utils/helpers';
import { ExchangeRateContext } from '../../ExchangeRateContext';
import { apiConfig } from '../../ApiConfig';
import { message } from 'antd';

import { trackEvent } from '../../utils/googleAnalytics';

const CryptoCurrencyForm = ({ isOpen, closeModal, onFormSubmit, initialFormData, onEdit }) => {
  // State for form validation and form data
    const [validated, setValidated] = useState(false); // Validation state
    const [formData, setFormData] = useState(initialFormData); // Form data state
    const [allCryptoCurrency, setAllCryptoCurrency] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isFormDirty, setIsFormDirty] = useState(false); // Flag to check if form has been modified
    const [transactionType, setTransactionType] = useState('buy'); // State to keep track of the transaction type
    const [isDateFocused, setIsDateFocused] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const [addedCurrencies, setAddedCurrencies] = useState([]); // Array to store added Currencies

    // Access currency, exchangeRate, and currency toggle function from context
    const { currency, handleCurrencyToggle, loadingExchangeRate, exchangeRate } = useContext(ExchangeRateContext);


    const url = apiConfig.CRYPTOCURRENCYDIRECTORY;
    const method = 'GET';
    const localStorageKey = 'cryptocurrencydirectory';

    useEffect(() => {
        loadData();
    }, []);

    // Effect to reset form data when modal opens
    useEffect(() => {
        if (isOpen) {
            // Reset currency switch to INR when the form is opened
            if (currency !== 'INR') {
                handleCurrencyToggle(formData, setFormData, 'cryptoprice', true); // Toggle to INR
            }
            setFormData(initialFormData); // Reset form data if modal is open
            setValidated(false); // Clear validation state
            setIsFormDirty(false); // Clear dirty flag
            setTransactionType('buy'); // Reset transaction type
            setAddedCurrencies([]);
        }
    }, [isOpen]);

    const loadData = async (refresh = false) => {
        try {
            trackEvent('CryptoCurrency', 'request', 'Requesting crypto currency directory');
            const data = await fetchData(url, method, localStorageKey, refresh);
            setAllCryptoCurrency(data);
            trackEvent('CryptoCurrency', 'success', 'Successfully crypto currency directory');
        } catch (error) {
            message.error(`Error fetching rsus list: ${error.message}`);
        }
    };

    const currencyOptions = allCryptoCurrency.map(currency => ({
        value: currency.id,
        label: currency.name,
    }));

    useEffect(() => {
        if (!isOpen) return;
    
        const selectedCurrency = currencyOptions.find(option => option.value === initialFormData.cryptoid);
        setFormData({
            ...initialFormData,
            date: initialFormData.date ? formatDateToInputValue(initialFormData.date) : "",
        });
        setSelectedOption(selectedCurrency || null);
        setTransactionType(initialFormData.type)
        setValidated(false);
        setIsFormDirty(false);
    }, [isOpen, JSON.stringify(currencyOptions), JSON.stringify(initialFormData)]);

    // Handle tab change for transaction type
    const handleTabSelect = (type) => {
        setTransactionType(type); // Update the transaction type
        setFormData((prevData) => ({ ...prevData, type })); // Update form data with the selected type
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { id, value } = e.target; // Get input field ID and value
        let newValue = value;

        // If input is for a number field, remove commas for processing
        if (id === 'cryptoprice') {
        newValue = value.replace(/,/g, ''); // Remove commas
        if (currency === 'INR') {
            newValue = formatRupees(newValue); // Add commas for INR
        }
        }

        setFormData((prevData) => ({ ...prevData, [id]: newValue }));
        setIsFormDirty(true); // Mark form as dirty
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        setIsButtonLoading(true);
        const form = event.currentTarget; // Get the form element
        
        // Check form validity
        if (form.checkValidity() === false && addedCurrencies.length === 0) {
            event.stopPropagation(); // Stop submission if form is invalid
            setValidated(true); // Show validation feedback
            setIsButtonLoading(false);
        } else {
            // Additional validation for required fields
            if (addedCurrencies.length === 0 && (!formData.cryptoid || !formData.quantity || !formData.cryptoprice || !formData.date)) {
                setValidated(true); // If the form is blank and nothing has been added, show validation error
                setIsButtonLoading(false);
                return;
            }  
            // Ensure investedAmount is in INR before submitting
            if (currency === 'USD') {
                const amountInUSD = parseFloat(formData.cryptoprice) || 0;
                const amountInINR = (amountInUSD / exchangeRate).toFixed(2);
                formData.cryptoprice = formatRupees(amountInINR); // Convert to INR
            }
            try {
                const { name, ...rest } = formData; // Destructure to exclude name and capture companyid
                // If there is data in the form, add it to the added companies list
                const updatedFormData = { ...rest, date: ConvertDateToMilliseconds(formData.date), type: transactionType };
    
                let finalAddedCurrencies = [...addedCurrencies];
                // Remove the 'name' field directly from the existing addedCurrencies
                finalAddedCurrencies = finalAddedCurrencies.map(currency => {
                    const { name, ...currencyWithoutName } = currency; // Exclude 'name' from existing currencies
                    return currencyWithoutName; // Return the updated currency object without the 'name'
                });
                
                if (formData.cryptoid && formData.quantity && formData.cryptoprice) {
                    // Merge the form data only if it's not blank
                    finalAddedCurrencies = [...finalAddedCurrencies, updatedFormData];
                }
                // Call the callback function with the entire list of added companies
                await onFormSubmit(finalAddedCurrencies);
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setIsButtonLoading(false);
            }
        }
    };

    const handleAddMore = (event) => {
        event.preventDefault();
        if (!selectedOption || !formData.cryptoid || !formData.quantity || !formData.cryptoprice || !formData.date) {
            setValidated(true); // Validate the form fields
            return;
        }
        // Prepare the new company data
        let newCurrencyData = {
            ...formData,
            name: selectedOption.label,
            date: ConvertDateToMilliseconds(formData.date),
            type: transactionType,
        };

        // Set the updated companies list, including the new company
        setAddedCurrencies([...addedCurrencies, newCurrencyData]);
        
        // Reset the form fields
        setFormData({
            name: '',
            cryptoid: '',
            quantity: '',
            cryptoprice: '',
            date: '',
        });
        // Reset the select input
        setSelectedOption(null); // Clear selected option
        setTransactionType('buy'); // Reset transaction type
        setValidated(false); // Reset validation
        setIsFormDirty(false); // Mark form as clean
    };
    
    const handleDelete = (index) => {
        // Remove the item from the list
        setAddedCurrencies(addedCurrencies.filter((_, i) => i !== index));
    };
    
    
    const handleEdit = (index) => {
        // Find the item to edit
        const itemToEdit = addedCurrencies[index];
        // Find the corresponding company option
        const selectedCurrency = currencyOptions.find(option => option.value === itemToEdit.cryptoid);
        // Format the date for the input field
        const formattedDate = itemToEdit.date ? formatDateToInputValue(itemToEdit.date) : "";
        // Fill the form with the selected item's data for editing
        setFormData({
            ...itemToEdit,
            cryptoid: itemToEdit.cryptoid,
            date: formattedDate, // Set the formatted date
            stockprice: itemToEdit.stockprice
        });
        
        setSelectedOption(selectedCurrency); // Set the selected option for react-select
        setTransactionType(itemToEdit.type);
        // Remove the item from the list for now
        handleDelete(index)
    };
    // Check if all fields are empty
    const areFieldsEmpty = Object.values(formData).every(value => value === '');

    // Handle modal close
    const handleClose = () => {
        // Only ask for confirmation if the form is dirty and not all fields are empty
        if (isFormDirty && !areFieldsEmpty) {
        const confirmClose = window.confirm('Are you sure you want to exit? Unsaved changes will be lost.');
        if (!confirmClose) {
            return; // Prevent closing if user cancels
        }
        }
        closeModal(); // Close the modal
    };

    // Render the form modal
    return (
        <Modal show={isOpen} onHide={handleClose} backdrop="static">
            {/* Modal Header */}
            <Modal.Header closeButton>
                <Modal.Title>Transaction Details</Modal.Title>
            </Modal.Header>
            {/* Modal Body */}
            <Modal.Body>
                {/* Toggle buttons for switching between Buy and Sell */}
                <div className="toggle-button-group">
                {/* Button for Buy */}
                <button
                    className={`toggle-button ${transactionType === 'buy' ? 'active' : ''} ${onEdit && transactionType === 'sell' ? 'disable' : ''}`}
                    onClick={() => handleTabSelect('buy')}
                    disabled={onEdit && transactionType === 'sell'}
                >
                    Buy
                </button>
                {/* Button for Sell */}
                <button
                    className={`toggle-button ${transactionType === 'sell' ? 'active' : ''} ${onEdit && transactionType === 'buy' ? 'disable' : ''}`}
                    onClick={() => handleTabSelect('sell')}
                    disabled={onEdit && transactionType === 'buy'}
                >
                    Sell
                </button>
                </div>

                {/* Form with validation */}
                <Form noValidate validated={validated} onSubmit={handleSubmit} className='crypto-currency'>
                <Form.Group controlId="name">
                    <Select
                        className='select-company-name'
                        options={currencyOptions}
                        placeholder="Select a cryptocurrency *"
                        onChange={(selectedOption) => {
                            setSelectedOption(selectedOption); // Update selected option
                            setFormData({ ...formData, cryptoid: selectedOption ? selectedOption.value : '' });
                        }}
                        value={selectedOption}
                        isSearchable
                        required
                        styles={{
                            placeholder: (provided) => ({
                                ...provided,
                                color: 'rgb(214 219 225)' // Change to your desired color
                            })
                        }}
                        isDisabled={onEdit}
                    />
                    {validated && !selectedOption && (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                            Please select the crypto currency.
                        </div>
                    )}
                </Form.Group>
                <Form.Group controlId="quantity">
                    <Form.Control
                    type="number"
                    placeholder="Quantity *"
                    value={formData.quantity}
                    onChange={handleInputChange}
                    required
                    />
                    <Form.Control.Feedback type="invalid">
                    Please enter the quantity.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="cryptoprice" className="input-container">
                    <span className={`input-label ${formData.cryptoprice ? 'has-value' : ''}`}>{currency === 'USD' ? '$' : '₹'}</span>
                    <Form.Control
                        type="text"
                        placeholder="Price *"
                        value={formData.cryptoprice}
                        autoComplete="off"
                        onChange={handleInputChange}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                    Please enter the price.
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="form-check form-switch">
                    <label className="form-check-label" htmlFor="currencySwitch">
                    Add price in USD
                    </label>
                    <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="currencySwitch"
                    disabled={loadingExchangeRate}
                    checked={currency === 'USD'}
                    onChange={() => handleCurrencyToggle(formData, setFormData, 'cryptoprice')}
                    />
                </div>
                <Form.Group controlId="date">
                    <div className={`position-relative ${!formData.date ? 'placeholder-date' : ''}`}>
                    {!formData.date && !isDateFocused && (
                        <span className='date-placeholder'>
                        Investment Date *
                        </span>
                    )}
                    <Form.Control
                    type="date"
                    value={formData.date}
                    onChange={handleInputChange}
                    placeholder="Date"
                    required
                    onFocus={(e) => {
                        if (!formData.date) {
                            setIsDateFocused(true); // Only set focus state if date is not filled
                            e.target.showPicker();  // Show the picker when focused
                        }
                    }}
                    onBlur={() => setIsDateFocused(false)} // Show placeholder on blur if no date
                    />
                    <Form.Control.Feedback type="invalid">
                        Please select the date.
                    </Form.Control.Feedback>
                    </div>
                </Form.Group>
                {!onEdit && (
                        <>
                            <a className="add-more" onClick={handleAddMore}>
                            Add More
                            </a>
                            {addedCurrencies.length > 0 && (
                            <div className="added-companies-list">
                                <table className="companies-table">
                                <thead>
                                    <tr>
                                    <th className="table-head-name">Name</th>
                                    <th>Qty</th>
                                    <th>Amount</th>
                                    <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addedCurrencies.map((currency, index) => (
                                    <tr key={index} className={currency.type}>
                                        <td className="table-row-name">
                                        <div className="name-wrapper">
                                            <span>{currency.name}</span>
                                        </div>
                                        </td>
                                        <td>
                                        <span>{currency.quantity}</span>
                                        </td>
                                        <td>
                                        <span>
                                            ₹
                                            {currency.cryptoprice}
                                        </span>
                                        </td>
                                        <td>
                                        <span>
                                            <i
                                            className="fas fa-edit edit-company"
                                            onClick={() => handleEdit(index)}
                                            ></i>
                                            <i
                                            className="fas fa-trash delete-company"
                                            onClick={() => handleDelete(index)}
                                            ></i>
                                        </span>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                            )}
                        </>
                    )}
                    <Form.Group controlId="propertySubmit" className="btn-container">
                        <Button variant="primary" type="submit" disabled={isButtonLoading}>
                        {isButtonLoading ? (onEdit ? 'Updating...' : 'Saving...') : (onEdit ? 'Update' : 'Save')}
                        </Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

// Export the CryptoCurrencyForm component as the default export
export default CryptoCurrencyForm;
